export class Fonts {
  public static MidCenturySans: string = 'Mid Century Sans';
  public static MidCenturySansBold: string = 'Mid Century Sans Bold';
  public static MidCenturySansSMBold: string = 'Mid Century Sans SmBold';
  public static MidCenturySansXBold: string = 'Mid Century Sans XBold';
  public static MidCenturySansHeavy: string = 'Mid Century Sans Heavy';
  public static MidCenturySansLight: string = 'Mid Century Sans XLight';
  public static MidCenturySansThin: string = 'Mid Century Sans Thin';
  public static SairaCondensedThin: string = 'Saira Extra Condensed Thin';
  public static SairaCondensedBold: string = 'Saira Extra Condensed Bold';
  public static SairaCondensed: string = 'Saira Extra Condensed';
}

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Colors } from '../Colors';
import { Fonts } from '../Fonts';

const TAPROOM_HOURS = [
  { day: 'SUN', hours: '9AM-1PM' },
  { day: 'MON', hours: '7:30-1PM' },
  { day: 'TUE', hours: '7:30-1PM' },
  { day: 'WED', hours: '7:30-1PM' },
  { day: 'THUR', hours: '7:30-1PM' },
  { day: 'FRI', hours: '7:30-1PM' },
  { day: 'SAT', hours: '9AM-1PM' },
];

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container style={{ padding: 20 }}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={5}>
          <Typography
            style={{
              fontFamily: Fonts.MidCenturySansBold,
              fontSize: isMobile ? 12 : 18,
            }}
          >
            HOURS
          </Typography>
          <table
            style={{
              textAlign: 'right',
            }}
          >
            {TAPROOM_HOURS.map((hoursForDay, index) => {
              return (
                <tr key={index}>
                  <th
                    style={{
                      width: isMobile ? 30 : 60,
                      fontSize: isMobile ? 12 : 18,
                    }}
                  >
                    {hoursForDay.day}
                  </th>
                  <td style={{ fontSize: isMobile ? 12 : 18 }}>
                    {hoursForDay.hours}
                  </td>
                </tr>
              );
            })}
          </table>
        </Grid>

        <Grid item xs={7}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10743.577195836899!2d-122.3130454!3d47.6864727!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54901562bd7dbf8d%3A0x23f35988d3ad6553!2sFull%20Tank%20Coffee!5e0!3m2!1sen!2sus!4v1713486335047!5m2!1sen!2sus"
            width={'100%'}
            height={'100%'}
            loading="lazy"
          ></iframe>
        </Grid>
      </Grid>

      <Grid container style={{ padding: '20px 0 70px' }}>
        {/* <Grid item style={{ width: "100%", padding: 20 }}>
          <Grid container spacing={5}>
            <Grid item lg={1} md={12}></Grid>
            <Grid item lg={5} md={12}>
              <HoursAndMap />
            </Grid>
            <Grid item lg={5} md={12}>
              <SubscribeForm />
            </Grid>
            <Grid item lg={1} md={12}></Grid>
          </Grid>
        </Grid> */}

        <Grid item sm={12}>
          {/*=======  copyright text  =======*/}
          <div>
            &copy; {new Date().getFullYear() + ' '}
            <a href="https://www.fulltankcoffee.com" target="_blank">
              {`Full Tank Coffee `}
            </a>
            &nbsp;| All Rights Reserved {/* <span>(206) 123 4567</span> | */}
            {/* <span>{" project9" + "@project" + "9brewing.com"}</span> */}
          </div>
        </Grid>
        {/*=======  payment icon  
            <div className="payment-icon">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/icon/pay.png"}
                className="img-fluid"
                alt=""
              />
            </div>=======*/}
      </Grid>
    </Grid>
  );
};

export default Footer;

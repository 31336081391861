import React from 'react';
import './App.css';
import {
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { storage } from '.';
import background from './assets/background2.jpg';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { useWindowSize } from './hooks/useWindowSize';
import { Fonts } from './Fonts';
import Navigation from './components/Navigtion';
import Footer from './components/Footer';
import { Colors } from './Colors';

function App() {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [image, setImage] = React.useState<string>();
  const [image2, setImage2] = React.useState<string>();
  React.useEffect(() => {
    let storageRef = ref(storage);

    listAll(storageRef)
      .then(async (res) => {
        console.log(res);
        let allURLS: string[] = [];
        for (let itemRef of res.items) {
          let url = await getDownloadURL(itemRef);
          allURLS.push(url);
        }
        setImage(allURLS[1]);
        setImage2(allURLS[0]);
      })
      .catch(function (error) {
        console.log(error);
      });

    let downloadURLs: string[] = [];
  }, []);

  return (
    <div style={{ backgroundColor: '#001f4d' }}>
      <div>
        <div style={{ position: 'relative' }}>
          <img
            style={{
              width: '100vw',
              height: '100vh',
              filter: 'blur(4px)',
              objectFit: 'cover',
            }}
            src={background}
            className="App-logo"
            alt="logo"
          />
          <img
            style={{ top: 20, left: 20, height: 70, position: 'absolute' }}
            src={require('./assets/logo_main_white.png')}
            className="App-logo"
            alt="logo"
          />
          <div
            style={{
              position: 'absolute',
              top: 150,
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Typography
              style={{
                color: 'white',
                textAlign: 'center',
                fontFamily: Fonts.MidCenturySansHeavy,
                fontSize: '5vw',
                padding: 15,
              }}
            >
              NOW OPEN 7 DAYS A WEEK!
            </Typography>
          </div>
          <img
            src={require('./assets/fuel_your_morning.png')}
            style={{
              position: 'absolute',
              maxHeight: windowSize.height * 0.5,
              maxWidth: windowSize.width * 0.5,
              top: windowSize.height * 0.5,
              left: '50%',
              transform: 'translate(-50%, -50%)',
              filter: 'drop-shadow(2px 2px 40px #000000)',
            }}
          />
        </div>

        <div style={{ padding: 30 }}>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid item md={7} xs={12}>
              <Grid container justifyContent={'center'}>
                <Stack spacing={2}>
                  <Typography
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      fontFamily: Fonts.MidCenturySansLight,
                      fontSize: 20,
                    }}
                  >
                    Full Tank Coffee is nestled within the vibrant atmosphere of
                    Project 9 Brewing. We are obsessed with quality, starting
                    with our carefully selected beans. What sets us apart is our
                    commitment to variety and exploration. Our roasters are
                    constantly rotating, bringing you a dynamic range of flavors
                    and profiles from both local artisans and esteemed roasters
                    worldwide.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src={image}
                style={{
                  marginTop: 5,
                  width: '85%',
                  border: '1.5vw solid white',
                  boxShadow: '0px 0px 10px grey',
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            style={{ paddingTop: 20 }}
            alignItems={'center'}
            spacing={2}
          >
            {!isMobile && (
              <Grid item xs={5}>
                <img
                  src={image2}
                  style={{
                    width: '85%',
                    border: '1.5vh solid white',
                    boxShadow: '0px 0px 10px grey',
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={7}>
              <Typography
                style={{
                  color: 'white',
                  textAlign: 'center',
                  fontFamily: Fonts.MidCenturySansLight,
                  fontSize: 20,
                }}
              >
                Here, our skilled baristas, trained in the fine art of coffee
                crafting, transform these exceptional beans into masterpieces
                that awaken your senses and brighten your day. We believe that
                every cup is not just a drink but also fuel for your day.
                Whether you're starting your morning with a single-origin
                espresso or seeking a midday pick-me-up, our coffees are
                designed to energize and inspire you.
              </Typography>
            </Grid>
            {isMobile && (
              <Grid item xs={12} md={5}>
                <img
                  src={image2}
                  style={{
                    width: '90%',
                    border: '2vh solid white',
                    boxShadow: '0px 0px 10px grey',
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>

        {/* 
          
          
        </div> */}
        {/* <Typography
          style={{
            fontFamily: Fonts.MidCenturySansBold,
            fontSize: isMobile ? 18 : 36,
          }}
        >
          @ PROJECT 9 BREWING
        </Typography>
        <Typography
          variant={'subtitle1'}
          onClick={() => {
            window.open('https://goo.gl/maps/o5nfz9WNVSVZRoMt6');
          }}
          style={{
            paddingTop: 20,
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 22,
            fontFamily: Fonts.MidCenturySans,
            color: Colors.project9Blue,
          }}
        >
          1409 NE 80TH ST · SEATTLE · WASHINGTON
        </Typography>
        <Card style={{ marginTop: 15 }}>
          <CardContent>
            <Typography
              variant="h6"
              style={{ fontFamily: Fonts.MidCenturySansBold }}
            >
              Hours
            </Typography>
            <Typography
              variant="h6"
              style={{ fontFamily: Fonts.MidCenturySans }}
            >
              Wed - 8AM-1PM
            </Typography>
            <Typography
              variant="h6"
              style={{ fontFamily: Fonts.MidCenturySans }}
            >
              Thur - 8AM-1PM
            </Typography>
            <Typography
              variant="h6"
              style={{ fontFamily: Fonts.MidCenturySans }}
            >
              Fri - 8AM-1PM
            </Typography>
            <Typography
              variant="h6"
              style={{ fontFamily: Fonts.MidCenturySans }}
            >
              Sat - 8AM-1PM
            </Typography>
            <Typography
              variant="h6"
              style={{ fontFamily: Fonts.MidCenturySans }}
            >
              Sun - 8AM-1PM
            </Typography>
          </CardContent> 
        </Card>*/}
      </div>
      <div style={{ backgroundColor: 'white' }}>
        <Footer />
      </div>
    </div>
  );
}

const Line = (props: any) => {
  return (
    <Typography
      style={{
        color: 'white',
        textAlign: 'center',
        fontFamily: Fonts.MidCenturySansLight,
        fontSize: 20,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default App;

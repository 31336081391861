import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './SairaExtraCondensed.css';
import './MidCenturySans.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDk_IH0ZsiU94a7Ht2MYvfqo10N8Kq3ZXU',
  authDomain: 'full-tank-coffee.firebaseapp.com',
  projectId: 'full-tank-coffee',
  storageBucket: 'full-tank-coffee.appspot.com',
  messagingSenderId: '129957155596',
  appId: '1:129957155596:web:2724a2902861fdf2fa6f29',
  measurementId: 'G-DYQRJBQREM',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const storage = getStorage(firebaseApp);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { storage };
